import React, { useCallback, useContext } from 'react';
import { render_product_load } from '../backend/render/product';
import { LsDataContainer } from '../components/LsContainer';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { sku_is_voucher, voucher_sku } from '../functions/product';
import { CartPositionContext } from './context/CartPositionContext';

export const loadProduct = async ({ sku, view }) => {
	return await render_product_load({
		sku,
		view,
	});
};

export const ProductContext = React.createContext(null);

export const ProductContainer = ({ children, sku, ...props }) => {
	const position_ctx = useContext(CartPositionContext);

	const extendContext = useCallback(({ data, functions }) => {
		if (!_.isNil(data)) {
			const isVoucher = sku_is_voucher(data.sku);
			const realSKU = isVoucher ? voucher_sku : sku;
			data.openProductsLink = `/product/${realSKU}`;
		}
		return { data, functions };
	}, []);

	const loader = useCallback(async () => {
		const isVoucher = sku_is_voucher(sku);
		const realSKU = isVoucher ? voucher_sku : sku;
		const ret = await loadProduct({
			sku: realSKU,
			view: props.view,
		});
		// console.log('isVoucher', isVoucher);
		if (isVoucher) {
			ret.sku = sku;
			if (position_ctx) {
				ret.name_text = position_ctx.NAME_TEXT;
				ret.brutto_sp = position_ctx.PRICE;
				ret.real_brutto_sp = position_ctx.PRICE;
				ret.delivery_time_working_days = 1000;
			}
		}

		// 		data: {
		// 			sku: position_ctx.SKU,
		// 			name_text: position_ctx.NAME_TEXT,
		// 			real_brutto_sp: position_ctx.PRICE,
		// 		},
		return ret;
	}, [sku, position_ctx]);

	return (
		<LsDataContainer
			id={{ sku }}
			context={ProductContext}
			loader={loader}
			extendContext={extendContext}
			{...props}
		>
			{children}
		</LsDataContainer>
	);
};
ProductContainer.propTypes = {
	...LsDataContainer.propTypes,
	sku: PropTypes.number.isRequired,
	view: PropTypes.string,
};
