import { cva } from 'class-variance-authority';
import React, { PropsWithChildren } from 'react';
import _ from 'lodash';
import { TLsFormInputDecorationProps } from '@ui/LsForm/types';
import { LsLabel } from '@ui/LsLabel';
import { LsAlert } from '@ui/LsAlert';
import { cn } from '@shadcn/lib/utils';
import { tr } from '@shared/legacy_compat/nextjs/translate';

const editIconStateVariants = cva('', {
  variants: {
    padding: {
      true: 'py-2 px-3',
      false: '',
    },
    disabled: {
      false: '',
    },
    editIconState: {
      true: 'pl-3 pr-8 min-h-9',
      false: '',
    },
  },
});

const inputDecorationFrame = cva('relative border rounded', {
  variants: {
    disabled: {
      false:
        'border-gray-300 hover:ring-black hover:ring-2 focus-within:ring-primary hover:focus-within:ring-primary focus-within:ring-2 focus-within:border-primary hover:focus-within:border-primary',
    },
  },
  defaultVariants: {},
});

const inputDecoration = cva('group relative', {
  variants: {
    disabled: {
      false: '',
      true: 'opacity-50',
    },
    outerPadding: {
      true: 'pt-2',
      false: '',
    },
  },
  defaultVariants: {
    outerPadding: true,
  },
});
export const LsInputDecoration = ({
  children,
  label,
  className,
  disabled = false,
  editIcon = null,
  padding = true,
  outerPadding = true,
  errors = [],
  showError = true,
  showErrorMessages = true,
  errorGutter = true,
}: PropsWithChildren<TLsFormInputDecorationProps>) => {
  const editIconState = !_.isNil(editIcon);
  const realEditIcon = editIconState
    ? React.cloneElement(editIcon as React.ReactElement<any>, { disabled })
    : null;

  const errorExisting = errors.length > 0;
  const renderErrorFrame = errorExisting && showError;
  const renderErrorMessage = errorExisting && showErrorMessages && showError;

  return (
    <div
      className={cn('', inputDecoration({ disabled, outerPadding }), className)}
    >
      <div
        className={cn(inputDecorationFrame({ disabled }), {
          'border-alert-error': renderErrorFrame,
        })}
      >
        <div
          className={cn(
            editIconStateVariants({ editIconState, disabled, padding }),
          )}
        >
          {editIcon && (
            <div className={'absolute top-1 right-1'}>{realEditIcon}</div>
          )}
          <div className={cn('LsInputDecoration-inner', { 'pt-1': padding })}>
            {children}
          </div>
        </div>
      </div>
      {renderErrorMessage && (
        <LsAlert
          variant={'text'}
          color={'error'}
          className={cn('pt-0.5 pl-4 text-xs min-h-5 max-h-5', {})}
        >
          {errors.map((errorText) => {
            return _.isObject(errorText) ? tr(errorText) : errorText;
          })}
        </LsAlert>
      )}
      {errorGutter && !renderErrorMessage && (
        <div className={'min-h-5 max-h-5'} />
      )}
      <div
        className={cn(
          'absolute bg-background text-background top-0 left-2.5 text-xs w-min',
          'px-0.5',
        )}
      >
        <LsLabel text={label} className={'text-nowrap'} />
      </div>
      <div
        className={cn(
          'absolute top-0 left-2.5 text-xs w-min group-focus-within:text-primary',
          'px-0.5 z-10',
        )}
      >
        <LsLabel text={label} className={'text-nowrap'} />
      </div>
    </div>
  );
};
