import { TTranslation } from '@shared/types';

export const trGiftCardSlogan: TTranslation = {
  DE: 'Verschenken Sie etwas Besonderes: Mit unserem LToys Einkaufsgutschein öffnen Sie Türen zu unvergesslichen Spielmomenten und grenzenloser Freude! Seien es Bausteine für kreative Köpfe, Karten für Sammler oder Modelle für die Sammlung! Für jeden ist etwas dabei.',
  EN: 'Give something special: With our LToys gift card, you open doors to unforgettable play moments and boundless joy! Whether it’s building blocks for creative minds, cards for collectors, or models for the collection! There’s something for everyone.',
  FR: 'Avec notre bon d’achat LToys, vous offrez bien plus qu’un simple cadeau. Vous ouvrez la porte à des moments de jeu uniques et à une joie infinie ! Que ce soit des blocs de construction pour stimuler les esprits créatifs, des cartes rares pour les passionnés de collection ou des modèles exclusifs pour les amateurs, il y en a pour tous les goûts et toutes les passions.',
};

export const trGiftCard: TTranslation = {
  DE: 'Geschenkkarte',
  EN: 'Gift card',
  FR: 'Carte-cadeau',
};

export const trGiftCardTitle: TTranslation = {
  DE: 'LToys Geschenkkarte',
  EN: 'LToys gift card',
  FR: 'Carte-cadeau LToys',
};

export const trDigital: TTranslation = {
  DE: 'Digital',
  EN: 'Digital',
  FR: 'Numérique',
};

export const trGiftcardYouGetDigital: TTranslation = {
  DE: 'Sie erhalten den Gutschein zum selbst ausdrucken auf digitalem Wege nach der Bestellung per E-Mail. Der darauf enthaltene Code kann sofort in unserem Online-Shop oder in unserem Ladengeschäft verwendet werden.',
  EN: 'You will receive the voucher to print out digitally by e-mail after placing your order. The code on the voucher can be used immediately in our webshop or in our store.',
  FR: 'Après avoir passé votre commande, vous recevrez le bon à imprimer vous-même par e-mail. Le code contenu dans le bon pourra être utilisé immédiatement dans notre webshop ou dans notre magasin.',
};

export const trErrorGiftcardNumberOrOnlineCodeWrong: TTranslation = {
  DE: 'Kartennummer oder Online-Code falsch',
  EN: 'Card number or online code wrong',
  FR: 'Numéro de carte ou code en ligne erroné',
};

export const trYourGiftcard: TTranslation = {
  DE: 'Ihre Geschenkkarte',
  EN: 'Your gift card',
  FR: 'Votre carte-cadeau',
};
export const trHasACreditBalance: TTranslation = {
  DE: 'verfügt über ein Guthaben von',
  EN: 'has a credit balance of',
  FR: "dispose d'un crédit de",
};

export const trCheckAnotherGiftcard: TTranslation = {
  de: 'Weitere Karte prüfen',
  en: 'Check another cift card',
  fr: 'Vérifier une autre carte-cadeau',
};

export const trGiftcardRedeemYourCredit: TTranslation = {
  de: 'Geschenkkarte: Guthaben einlösen',
  en: 'Giftcard: Redeem your credit',
  fr: 'Carte-cadeau : Échangez votre crédit',
};

export const trGiftcardAdd: TTranslation = {
  de: 'Geschenkkarte hinzufügen',
  en: 'Add gift card',
  fr: 'Ajouter une carte-cadeau',
};

export const trCredit: TTranslation = {
  de: 'Guthaben',
  en: 'Credit',
  fr: 'Avoir',
};

export const trErrorGiftcardAlreadyAdded: TTranslation = {
  de: 'Geschenkkarte wurde bereits hinzugefügt.',
  en: 'Gift card already added.',
  fr: 'Carte cadeau déjà ajoutée.',
};

export const trErrorGiftcardNoBalance: TTranslation = {
  de: 'Guthaben der Geschenkkarte aufgebraucht.',
  en: 'Gift card balance used up.',
  fr: 'Solde de la carte cadeau épuisé.',
};

export const trRemoveGiftcardConfirmTitle: TTranslation = {
  de: 'Geschenkkarte entfernen',
  en: 'Remove gift card',
  fr: 'Supprimer la carte-cadeau',
};

export const trRemoveGiftcardConfirmBody: TTranslation = {
  de: 'Möchten sie wirklich das Guthaben der Geschenkkarte entfernen?',
  en: 'Do you really want to remove the credit balance of the gift card?',
  fr: 'Voulez-vous vraiment supprimer le solde de la carte cadeau ?',
};

export const trGiftcardAmountSummary: TTranslation = {
  de: 'Guthaben von Geschenkkarte',
  en: 'Giftcard credit',
  fr: 'Solde de la carte cadeau',
};

export const trGiftcardNoPointNoVoucher: TTranslation = {
  de: 'Auf Bestellungen die eine Geschenkkarte enthalten, können keine VIP-Punkte oder Coupons eingelöst, sowie keine Geschenkkarten als Zahlungsmittel eingesetzt werden.',
  en: 'No VIP points or coupons can be redeemed on orders containing a gift card, nor can gift cards be used as a means of payment.',
  fr: 'Aucun point VIP ou coupon ne peut être échangé contre une commande contenant une carte cadeau, et aucune carte cadeau ne peut être utilisée comme moyen de paiement.',
};
