import { TTranslation } from '@shared/types';

export const trFirstname: TTranslation = {
  de: 'Vorname',
  en: 'Firstname',
  fr: 'Prénom',
};
export const trLastname: TTranslation = {
  de: 'Nachname',
  en: 'Lastname',
  fr: 'Nom',
};

export const trPrivate: TTranslation = {
  de: 'Privatkunde',
  en: 'Private Customer',
};

export const trCompany: TTranslation = {
  de: 'Unternehmen',
  en: 'Company',
  fr: 'Entreprise',
};

export const trInstitution: TTranslation = {
  DE: 'Öffentliche Einrichtung ',
  EN: 'Public Institution',
};

export const trCustomerType: TTranslation = {
  de: 'Kundentyp',
  en: 'Customer type',
};

export const trPhoneNumber: TTranslation = {
  de: 'Telefonnummer',
  en: 'Phone number',
  fr: 'Numéro de téléphone',
};
