import React, { useMemo, useRef, useState } from 'react';
import Card from '@material-ui/core/Card';
import { Hidden, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import Grid from '@material-ui/core/Grid';
import { HeaderCart } from './HeaderCart';
import { HeaderTranslation } from './HeaderTranslation';
import { HeaderAccount } from './HeaderAccount';
import { HeaderIconPortalPlacer } from './HeaderIconPortal';
import PropTypes from 'prop-types';
import { MuiCSSContainer } from '../MuiCSSContainer';
import { HeaderIconPortalButton } from './HeaderIconPortalButton';
import { HeaderSearchIcon } from './HeaderSearchIcon';
import { HeaderHamburgerMenu } from './HeaderHamburgerMenu';
import { ShopSearchBar } from '../ShopSearch/ShopSearchBar';
import { getTranslation } from '@lsoft/translate';
import { useRouter } from 'next/router';
import { tr_visit_our_letzshop_profile } from '@lsoft/shared/translations/links';
import { HeaderLinkLogo } from './HeaderLinkLogo';
import { HeaderLinkLetzshop } from './HeaderLinkLetzshop';
import { HeaderLinkGiftfinder } from './HeaderLinkGiftfinder';
import { HeaderLinkGiftCard } from './HeaderLinkGiftCard';
import { cn } from '@lsoft/shadcn/lib/utils';

const useStyles = makeStyles((theme) => ({
	HeaderCard: {
		height: theme.sizes.appBarHeight - 2,
		padding: theme.spacing(1),
		overflow: 'visible',
	},
	HeaderNav: {
		height: theme.sizes.appBarHeight,
		zIndex: theme.zIndexes.Header,
	},
	HeaderOuterGrid: {
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
		position: 'relative', // see .IconPortal
	},
}));

export const HeaderContext = React.createContext(null);
HeaderContext.displayName = 'HeaderContext';

// import {Image} from "@next/I"
export const Header = ({ loginPage, theme }) => {
	const [selectedIconPortal, setSelectedIconPortal] = useState(null);
	const activeIconRef = useRef(null);
	const classes = useStyles({ theme });
	const value = {
		activeIconRef,
		selectedIconPortal,
		setSelectedIconPortal,
	};

	const { locale } = useRouter();

	const letzshop_link_lc = locale !== 'fr' ? 'de' : locale;

	return (
		<HeaderContext.Provider value={value}>
			<nav className={clsx(classes.HeaderNav)}>
				<Card elevation={2} className={clsx(classes.HeaderCard)} square>
					<MuiCSSContainer>
						<Grid
							container
							direction={'row'}
							justifyContent={'space-between'}
							alignItems={'center'}
							className={clsx(classes.HeaderOuterGrid)}
						>
							<Grid item>
								<HeaderLinkLogo />
							</Grid>
							<Hidden smDown initialWidth={'md'}>
								<Grid item>
									<ShopSearchBar />
								</Grid>
							</Hidden>
							<Grid item>
								{/* Holding right icons */}
								<div
									className={cn('flex flex-row items-center gap-1 sm:gap-2')}
								>
									<Hidden mdUp initialWidth={'xs'}>
										{!loginPage && (
											<Grid item>
												<HeaderIconPortalButton name={'search'}>
													<HeaderSearchIcon />
												</HeaderIconPortalButton>
											</Grid>
										)}
									</Hidden>
									{!loginPage && (
										<>
											<Grid item>
												<HeaderLinkGiftCard />
											</Grid>
											<div className={cn('hidden sm:block')}>
												{/*<Grid item>*/}
												<HeaderLinkGiftfinder />
												{/*</Grid>*/}
											</div>
											<Grid item>
												<HeaderLinkLetzshop />
											</Grid>
										</>
									)}
									<Hidden smDown initialWidth={'md'}>
										<Grid item>
											<HeaderIconPortalButton name={'translation'}>
												<HeaderTranslation />
											</HeaderIconPortalButton>
										</Grid>
									</Hidden>
									{!loginPage && (
										<Grid item>
											<HeaderIconPortalButton name={'cart'}>
												<HeaderCart />
											</HeaderIconPortalButton>
										</Grid>
									)}
									<Hidden smDown initialWidth={'md'}>
										{!loginPage && (
											<Grid item>
												<HeaderIconPortalButton name={'account'}>
													<HeaderAccount />
												</HeaderIconPortalButton>
											</Grid>
										)}
									</Hidden>
									<Hidden mdUp initialWidth={'xs'}>
										{!loginPage && (
											<Grid item>
												<HeaderIconPortalButton name={'tag-menu'}>
													<HeaderHamburgerMenu />
												</HeaderIconPortalButton>
											</Grid>
										)}
									</Hidden>
								</div>
							</Grid>
						</Grid>
					</MuiCSSContainer>
				</Card>
				<HeaderIconPortalPlacer />
			</nav>
		</HeaderContext.Provider>
	);
};

Header.propTypes = { loginPage: PropTypes.bool };
Header.defaultProps = { loginPage: false };
