import { TTranslation } from '@shared/types';

export const trMyAccount: TTranslation = {
  de: 'Mein Profil',
  en: 'My Account',
  pt: 'Minha Conta',
  lu: 'Mäin Account',
  fr: 'Mon compte',
};

export const trLogOut: TTranslation = {
  de: 'Abmelden',
  en: 'Log out',
  pt: 'Sair',
  lu: 'Loggt Iech aus',
  fr: 'Déconnexion',
};

export const trLogin: TTranslation = {
  de: 'Anmeldung',
  en: 'Login',
  pt: 'Login',
  lu: 'Login',
  fr: 'Connexion',
};

export const trLogIn: TTranslation = {
  de: 'Anmelden',
  en: 'Log in',
  pt: 'Entrar',
  lu: 'Loggt Iech an',
  fr: 'Se connecter',
};

export const trSelectLanguage: TTranslation = {
  de: 'Sprache wählen',
  en: 'Select language',
  pt: 'Selecione o idioma',
  lu: 'Sprooch auswielen',
  fr: 'Sélectionnez la langue',
};

export const trPleaseScanYourRfid: TTranslation = {
  de: 'Bitte scannen Sie Ihre RFID Karte um sich anzumelden.',
  en: 'Please scan your RFID card in order to log in.',
  pt: 'Por favor, escaneie seu cartão RFID para entrar.',
  lu: 'Scannt w.e.g. är RFID Kaart fir Iech unzemellen.',
  fr: 'Veuillez scanner votre carte RFID pour vous connecter.',
};

export const trPleaseEnterYourUsername: TTranslation = {
  de: 'Bitte geben Sie Ihren Usernamen und Ihr Passwort ein um sich anzumelden.',
  en: 'Please enter your username and password in order to log in.',
  pt: 'Por favor, insira seu nome de usuário e senha para entrar.',
  lu: 'Gitt w.e.g. ären Benotzernumm an Passwuert an fir Iech unzemellen.',
  fr: "Veuillez entrer votre nom d'utilisateur et votre mot de passe pour vous connecter.",
};

export const trWelcome: TTranslation = {
  de: 'Willkommen',
  en: 'Welcome',
  pt: 'Bem-vinda',
  lu: 'Wëllkomm',
  fr: 'Bienvenue',
};

export const trPlsWait: TTranslation = {
  de: 'Bitte warten',
  en: 'Please wait',
  pt: 'Por favor, aguarde',
  lu: 'Wann ech glift waarden',
  fr: "S'il vous plaît, attendez",
};

export const trUnknownError: TTranslation = {
  de: 'Ein unerwarteter Fehler ist aufgetreten.',
  en: 'An unexpected error has occurred.',
  fr: "Une erreur inattendue s'est produite.",
  pt: 'Ocorreu um erro inesperado.',
  lu: 'En onerwaarden Feeler ass opgetrueden.',
};

export const trLoginFailed: TTranslation = {
  de: 'Login fehlgeschlagen',
  en: 'Login failed',
  pt: 'Falha no login',
  lu: 'Login huet net geklappt',
  fr: 'Échec de la connexion',
};

export const trUsernamePasswordNotMatch: TTranslation = {
  de: 'Benutzername/Passwort stimmen nicht überein.',
  en: 'Username/password not matching.',
  pt: 'Nome de usuário/senha não correspondem.',
  lu: 'Benotzernumm/Passwuert passen net zesummen.',
  fr: "Le nom d'utilisateur/le mot de passe ne correspondent pas.",
};

export const trUnknownRfid: TTranslation = {
  de: 'Unbekannter RFID.',
  en: 'Unknown RFID',
  pt: 'RFID desconhecido',
  lu: 'Onbekannte RFID',
  fr: 'RFID inconnu',
};

export const trAccessRestricted: TTranslation = {
  de: 'Zugriff eingeschränkt',
  en: 'Access restricted',
  pt: 'Acesso restrito',
  lu: 'Zougrëff ass limitéiert',
  fr: 'Accès restreint',
};

export const trTheAccessToThisDataWasRestricted: TTranslation = {
  en: 'The access to this data was restricted. You are missing following scopes:',
  de: 'Der Zugriff auf diese Daten wurde eingeschränkt. Ihnen fehlen folgende Berechtigungen:',
  pt: 'O acesso a esses dados foi restrito. Você está perdendo os seguintes escopos:',
  lu: 'Den Zougrëff op dës Donnéeë gouf limitéiert. Dir feelen déi nofolgend Beräicher:',
  fr: "L'accès à ces données a été restreint. Vous manquez les scopes suivants :",
};

export const trEnterSearchTerm: TTranslation = {
  de: 'Suchbegriff eingeben',
  en: 'Enter search term',
  pt: 'Inserir termo de pesquisa',
  lu: 'Gebt een Sichbegreffer an',
  fr: 'Entrez un terme de recherche',
};

export const trSave: TTranslation = {
  de: 'Speichern',
  en: 'Save',
  pt: 'Salvar',
  lu: 'Späicheren',
  fr: 'Enregistrer',
};

export const trSaveFailed: TTranslation = {
  de: 'Speichern fehlgeschlagen.',
  en: 'Save failed',
  pt: 'Falha ao salvar',
  lu: 'Späicheren huet net geklappt',
  fr: 'Échec de la sauvegarde',
};

export const trApplicationModulesApiError: TTranslation = {
  en: 'Application modules api error',
  de: 'Anwendungsmodul-API-Fehler',
  pt: 'Erro na API dos módulos de aplicação',
  lu: 'API-Feeler vun den Applikatiounsmodulen',
  fr: "Erreur de l'API des modules d'application",
};

export const trBack: TTranslation = {
  de: 'Zurück',
  en: 'back',
  fr: 'retour',
};

export const trNext: TTranslation = {
  de: 'Weiter',
  en: 'next',
};

export const trCancel: TTranslation = {
  de: 'Abbrechen',
  en: 'Cancel',
  fr: 'Annuler',
};

export const trConfirm: TTranslation = {
  en: 'Confirm',
  de: 'Bestätigen',
};

export const trSelect: TTranslation = {
  en: 'Select',
  de: 'Auswählen',
};

export const trNotFound: TTranslation = {
  en: 'not found',
  de: 'nicht gefunden',
};

export const trPassword: TTranslation = {
  de: 'Passwort',
  en: 'Password',
  fr: 'Mot de passe',
};

export const trPasswordRepeat: TTranslation = {
  de: 'Passwort wiederholen',
  en: 'Password repeat',
  fr: 'Répétition du mot de passe',
};

export const trVatId: TTranslation = {
  de: 'Umsatzsteuer-ID',
  en: 'VAT ID',
};

export const trErrorUnknown: TTranslation = {
  de: 'Ein unbekannter Fehler ist aufgetreten.',
  en: 'An unexpected error has occurred.',
  fr: "Une erreur inattendue s'est produite.",
};

export const trNote: TTranslation = {
  de: 'Hinweis',
  en: 'Note',
  fr: 'Note',
};
