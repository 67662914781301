import _ from 'lodash';
import {
	contentLength,
	max,
	maxLength,
	minLength,
	notEmptyString,
	notEqual,
	validEmail,
	validTelephone,
} from '../Validator';
import { currencySign, DateTimeToDate12h, lowercase } from '../Converter';

export const text_numbers_only = {
	inputComponent: 'text',
	inputProps: {
		allowedCharacters: '[0-9]',
	},
};

export const kn8_code = _.merge({}, text_numbers_only, {
	inputComponent: 'text',
	inputProps: {
		label: { DE: 'KN8 Gütercode', EN: 'KN8 tariff number' },
		maxLength: 8,
	},
	validators: [contentLength(8)],
});

export const username = {
	inputComponent: 'text',
	inputProps: {
		label: { ALL: 'Username' },
	},
	validators: [notEmptyString()],
};

export const password = {
	inputComponent: 'password',
	inputProps: {
		label: { DE: 'Passwort', EN: 'Password' },
	},
	validators: [notEmptyString(), minLength(8)],
};

export const password_unvalidated = _.assign({}, password, {
	validators: [notEmptyString()],
});

export const country_of_origin = {
	inputComponent: 'text',
	inputProps: {
		label: { DE: 'Ursprungsland', EN: 'Country of origin' },
		allowedCharacters: '[A-Z,a-z[]',
		maxLength: 2,
		upperCase: true,
	},
	validators: [contentLength(2)],
};

export const weight_g = {
	inputComponent: 'number',
	inputProps: {
		suffix: 'g',
		label: { DE: 'Gewicht', EN: 'Weight' },
		textAlign: 'right',
		decimals: 2,
		decimalSeparator: ',',
	},
	validators: [notEqual(0)],
};

export const percent = {
	inputComponent: 'number',
	inputProps: {
		suffix: '%',
		textAlign: 'right',
		decimals: 0,
		decimalSeparator: ',',
	},
	validators: [notEqual(0), max(100)],
};

export const percent_tenth = _.merge({}, percent, {
	inputProps: {
		decimals: 1,
	},
});

export const tax_rate = _.merge({}, percent_tenth, {
	inputProps: {
		label: { DE: 'MwSt.-Satz', EN: 'Tax rate' },
	},
});

export const size_mm = {
	inputComponent: 'number',
	inputProps: {
		suffix: 'mm',
		textAlign: 'right',
		decimals: 1,
		decimalSeparator: ',',
	},
	validators: [notEqual(0)],
};

export const length_mm = _.merge({}, size_mm, {
	inputProps: {
		label: { DE: 'Länge', EN: 'Length' },
	},
});

export const height_mm = _.merge({}, size_mm, {
	inputProps: {
		label: { DE: 'Höhe', EN: 'Height' },
	},
});

export const width_mm = _.merge({}, size_mm, {
	inputProps: {
		label: { DE: 'Breite', EN: 'Width' },
	},
});

export const ean = {
	inputComponent: 'text',
	inputProps: {
		label: { ALL: 'EAN' },
		maxLength: 13,
	},
	validators: [minLength(8), maxLength(13)],
};

export const printed_filter = {
	inputComponent: 'select',
	inputProps: {
		label: {
			ALL: 'Gedruckt',
		},
		options: [
			{ value: 'PRINTED', label: { DE: 'Ja', EN: 'Yes' } },
			{
				value: 'NOT_PRINTED',
				label: { DE: 'Nein', EN: 'No' },
			},
			{ value: 'ALL', label: { DE: 'Alle', EN: 'All' } },
		],
	},
};

export const issue_date = {
	inputComponent: 'date',
	validators: [notEqual(null)],
	converters: [DateTimeToDate12h],
	inputProps: {
		label: {
			DE: 'Beleg Datum',
			EN: 'Issue date',
		},
	},
};

export const price = {
	inputComponent: 'price',
	inputProps: {
		label: {
			DE: 'Betrag',
			EN: 'Total',
		},
	},
};
export const currency_sign = {
	inputComponent: 'select',
	converters: [currencySign],
	inputProps: {
		label: {
			DE: 'Währung',
			EN: 'Currency',
		},
		options: [
			{
				value: '€',
				label: 'EUR',
			},
			{
				value: '$',
				label: 'USD',
			},
			{
				value: '£',
				label: 'GBP',
			},
		],
	},
};

export const simple_translation = {
	inputComponent: 'simple_translation',
};

export const html_translation = {
	inputComponent: 'html_translation',
	inputProps: {},
};

export const flags = {
	inputComponent: 'flags',
	inputProps: {},
};

export const items_per_page = {
	inputComponent: 'select',
	inputProps: {},
};

export const add_sku = _.merge({}, text_numbers_only, {
	validators: [contentLength(6)],
	inputProps: {
		label: { ALL: 'SKU' },
		maxLength: 6,
	},
});

export const add_skus = _.merge(
	{},
	{
		inputComponent: 'text',
		inputProps: {
			allowedCharacters: '[0-9\\s\\,]',
			label: { ALL: 'SKUS' },
		},
	},
);

export const filter_class = {
	inputComponent: 'select',
	inputProps: {
		label: { DE: 'Produktansicht', EN: 'Product view' },
	},
};

export const filter = {
	inputComponent: 'select',
	inputProps: {
		label: { ALL: 'Filter' },
	},
};

export const add_cetegory = {
	inputComponent: 'text',
	inputProps: {
		allowedCharacters: '[0-9\\.]',
		label: { DE: 'Kategorie', EN: 'Category' },
	},
};

export const product_selection_name = {
	inputComponent: 'text',
	inputProps: {
		label: { ALL: 'Name' },
	},
};

export const printers = {
	inputComponent: 'select',
	inputProps: {
		label: {
			DE: 'Drucker',
			EN: 'Printer',
		},
	},
};

export const stock_select = {
	inputComponent: 'select',
	selectorProps: ['value', 'errors', 'value2'],
	inputProps: {
		displayEmpty: true,
		placeholder: {
			EN: 'Choose a stock',
			DE: 'Wählen Sie ein Lager',
			FR: 'Choisir une stock',
		},
	},
};

export const from_stock_select = _.merge({}, stock_select, {
	inputProps: {
		label: { DE: 'Von Lager', EN: 'From stock' },
	},
});

export const to_stock_select = _.merge({}, stock_select, {
	inputProps: {
		label: { DE: 'Zu Lager', EN: 'To stock' },
	},
});

export const amount = {
	inputComponent: 'number',
	inputProps: {
		default: 0,
	},
};

export const input_select = {
	inputComponent: 'select',
	selectorProps: ['value', 'errors'],
	inputProps: {
		displayEmpty: true,
		options: [
			{ value: 'SKU', label: { ALL: 'SKU' } },
			{ value: 'SKUS', label: { ALL: 'SKUS' } },
			{
				value: 'CATEGORY',
				label: { DE: 'Kategorie', EN: 'Category' },
			},
		],
		placeholder: {
			EN: 'Choose an Input',
			DE: 'Wählen Sie ein Eingabemöglichkeit',
			FR: 'Choisir une stock',
		},
	},
};

export const hidden_bool = {
	inputComponent: 'switch',
};

export const active = {
	inputComponent: 'switch',
	inputProps: {
		label: {
			DE: 'Aktiv',
			EN: 'Active',
		},
	},
};

export const firstname = {
	inputComponent: 'text',
	inputProps: {
		allowedCharacters:
			'[A-Za-záÁàÀâÂäÄãÃåÅçÇðÐéÉèÈêÊëËíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕßþÞúÚùÙûÛüÜýÝÿ\\s\\.\\-\\/]',
		label: {
			DE: 'Vorname',
			EN: 'Firstname',
			FR: 'Prénom',
		},
	},
	validators: [minLength(2), maxLength(45)],
};

export const lastname = {
	inputComponent: 'text',
	inputProps: {
		allowedCharacters:
			'[A-Za-záÁàÀâÂäÄãÃåÅçÇðÐéÉèÈêÊëËíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕßþÞúÚùÙûÛüÜýÝÿ\\s\\.\\-\\/]',
		label: {
			DE: 'Nachname',
			EN: 'Lastname',
			FR: 'Nom',
		},
	},
	validators: [minLength(2), maxLength(45)],
};

export const email = {
	inputComponent: 'text',
	inputProps: {
		allowedCharacters: '[A-Za-z0-9._+-@]',
		label: {
			DE: 'E-Mail',
			EN: 'Email',
		},
		inputProps: {
			autoCorrect: 'off',
			autoCapitalize: 'off',
		},
	},
	validators: [validEmail('test@test.de'), minLength(2), maxLength(45)],
	converters: [lowercase],
};

export const customer_id = {
	inputComponent: 'text',
	inputProps: {
		allowedCharacters: '[0-9]',
		disabled: true,
		label: {
			DE: 'Kundennummer',
			EN: 'CustomerDetails number',
			FR: 'Numéro de client',
		},
	},
	validators: [],
};

export const address_addition = {
	inputComponent: 'text',
	inputProps: {
		allowedCharacters:
			'[0-9A-Za-záÁàÀâÂäÄãÃåÅçÇðÐéÉèÈêÊëËíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕßþÞúÚùÙûÛüÜýÝÿ\\s\\.\\,\\-\\/]',
		label: {
			DE: 'Adresszusatz (z. B. Firmenname)',
			EN: 'Additional information',
			FR: 'Informations complémentaires',
		},
	},
	validators: [minLength(2), maxLength(45)],
};

export const street = {
	inputComponent: 'text',
	inputProps: {
		allowedCharacters:
			'[0-9A-Za-záÁàÀâÂäÄãÃåÅçÇðÐéÉèÈêÊëËíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕßþÞúÚùÙûÛüÜýÝÿ\\s\\.\\,\\-\\/]',
		label: {
			DE: 'Straße',
			EN: 'Street',
			FR: 'Rue',
		},
	},
	validators: [minLength(2), maxLength(60)],
};

export const street_number = {
	inputComponent: 'text',
	inputProps: {
		allowedCharacters: '[0-9A-Za-z\\s\\.\\,\\-\\/]',
		label: {
			DE: 'Hausnummer',
			EN: 'House Number',
			FR: 'N° de maison',
		},
	},
	validators: [minLength(1), maxLength(60)],
};

export const zipcode = {
	inputComponent: 'text',
	inputProps: {
		allowedCharacters:
			'[0-9A-Za-záÁàÀâÂäÄãÃåÅçÇðÐéÉèÈêÊëËíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕßþÞúÚùÙûÛüÜýÝÿ\\s\\.\\-\\/]',
		label: {
			DE: 'Postleitzahl',
			EN: 'ZIP Code',
			FR: 'Code postal',
		},
	},
	validators: [minLength(2), maxLength(60)],
};

export const city = {
	inputComponent: 'text',
	inputProps: {
		allowedCharacters:
			'[A-Za-záÁàÀâÂäÄãÃåÅçÇðÐéÉèÈêÊëËíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕßþÞúÚùÙûÛüÜýÝÿ\\s\\.\\-\\/]',
		label: {
			DE: 'Ort',
			EN: 'City',
			FR: 'Lieu',
		},
	},
	validators: [minLength(2), maxLength(60)],
};

export const country_code = {
	inputComponent: 'select',
	inputProps: {
		label: {
			DE: 'Land',
			EN: 'Country',
		},
	},
};

export const pin_code = {
	inputComponent: 'pin_code',
	validators: [minLength(6), maxLength(6)],
};

export const addressTypeSelect = {
	inputComponent: 'select',
	inputProps: {
		options: [
			{
				value: 'INVOICE',
				label: {
					DE: 'Als Rechnungsanschrift anlegen',
					EN: 'Create as billing address',
					FR: 'Créer comme adresse de facturation',
				},
			},
			{
				value: 'DELIVERY',
				label: {
					DE: 'Als Lieferanschrift anlegen',
					EN: 'Create as delivery address',
					FR: 'Créer comme adresse de livraison',
				},
			},
			// {
			// 	value: 'ADDITIONAL_ADDRESS',
			// 	label: {
			// 		DE: 'Als weitere Anschrift anlegen.',
			// 		EN: 'Create as additional address.',
			// 		FR: 'Créer comme adresse supplémentaire.',
			// 	},
			// },
		],
		label: {
			DE: 'Adresstyp',
			EN: 'Address type',
			// eslint-disable-next-line quotes
			FR: "Type d'adresse",
		},
	},
};

export const search = {
	inputComponent: 'text',
	inputProps: {
		allowedCharacters:
			'[A-Za-záÁàÀâÂäÄãÃåÅçÇðÐéÉèÈêÊëËíÍìÌîÎïÏñÑóÓòÒôÔöÖõÕßþÞúÚùÙûÛüÜýÝÿ\\s\\.\\-\\/]',
		label: {
			DE: 'Ort',
			EN: 'City',
			FR: 'Lieu',
		},
	},
	validators: [minLength(2), maxLength(60)],
};

export const phone_number = {
	inputComponent: 'phone',
	inputProps: {
		allowedCharacters: '[0-9+]',
		label: {
			DE: 'Telefonnummer',
			EN: 'Phone number',
			FR: 'Numéro de téléphone',
		},
	},
	validators: [validTelephone()],
};
