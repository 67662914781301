// import { Tr } from '@lsoft/translate';
import { cn } from '@lsoft/ui/lib/utils';
import React from 'react';
import _ from 'lodash';
import { tr } from '@shared/legacy_compat/nextjs/translate';

export const LsLabel = ({ text, className = '' }) => {
  const label = React.isValidElement(text)
    ? text
    : _.isObject(text)
      ? tr(text)
      : text;
  return <span className={cn(className)}>{label}</span>;
};
