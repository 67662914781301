// eslint-disable-next-line no-unused-vars
import { useContext } from 'react';
import { PropTypes } from 'prop-types';
import { TrContext } from './TrProvider';
import _ from 'lodash';
import ReactHtmlParser from 'react-html-parser';
import { getTranslation } from './getTranslation';

const getWithTemplate = (string, context, stack) => {
	Object.entries(context).map(([key, value]) => {
		if (_.has(context, key)) {
			if (typeof value === 'object') {
				string = getWithTemplate(
					string,
					value,
					(stack ? stack + '.' : '') + key,
				);
			} else {
				string = string.replace(
					new RegExp(
						'\\$\\{\\s*' + (stack ? stack + '.' : '') + key + '\\s*\\}',
						'g',
					),
					value,
				);
			}
		}
	});
	return string;
};

const getHtml = (tr) => {
	return ReactHtmlParser(tr);
};

const getHtmlStripped = (tr) => {
	const tmp = document.createElement('div');
	tmp.innerHTML = tr;
	return tmp.textContent || tmp.innerHTML;
};

const getWithExcerpt = (tr, limit, excerptSuffix) => {
	if (tr.length <= limit) {
		return tr;
	} else {
		return tr.substr(0, tr.lastIndexOf(' ', limit)) + ' ' + excerptSuffix;
	}
};

export const Tr = ({
	path = null,
	tr = null,
	format = null,
	stripHtml = false,
	html = false,
	limit = 0,
	excerptSuffix = '...',
}) => {
	const trProvider = useContext(TrContext);
	let translation = getTranslation(
		!_.isNil(tr) ? tr : _.get(trProvider.tr, path, null),
		trProvider.activeLanguage,
		trProvider.availableLanguages,
	);

	translation = _.isNil(format)
		? translation
		: getWithTemplate(translation, format);

	translation = !stripHtml ? translation : getHtmlStripped(translation);

	translation =
		limit > 0 && translation.length > limit
			? getWithExcerpt(translation, limit, excerptSuffix)
			: translation;

	translation = !html ? translation : getHtml(translation);

	translation =
		_.isNil(translation) && !_.isNil(path) ? `_tr:${path}` : translation;
	return translation;
};

Tr.propTypes = {
	path: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	trProvider: PropTypes.object,
	tr: PropTypes.object,
	format: PropTypes.object,
	limit: PropTypes.number,
	html: PropTypes.bool,
	stripHtml: PropTypes.bool,
	excerptSuffix: PropTypes.any,
};
