import { TDictionary, TTranslation } from '@shared/types';
import { useRouter } from 'next/router';

import { realTr } from '@shared/nextjs/translate/realTr';

export const tr = (
  tr: string | TTranslation | undefined,
  variables?: TDictionary<string>,
) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { locale } = useRouter();
  return realTr(tr, locale ?? 'en', variables);
};
