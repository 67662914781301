import _ from 'lodash';

export type TSessionStateAddPaymentPayload = {
  hash: string;
  payment_method: string;
  giftcard_hash: string;
  amount: number;
};

export type TSessionPayment = {
  HASH: string;
  VOUCHER_HASH: string;
  AMOUNT: number;
  PAYMENT_METHOD: string;
};

export const updatePayment = (
  state: any,
  payload: TSessionStateAddPaymentPayload,
) => {
  const new_state = _.cloneDeep(state);
  new_state.PAYMENTS[payload.hash] = {
    HASH: payload.hash,
    VOUCHER_HASH: payload.giftcard_hash,
    AMOUNT: payload.amount,
    PAYMENT_METHOD: payload.payment_method,
  };
  return _.assign({}, state, new_state);
};

export type TRemovePaymentParameter = {
  hash: string;
};
export const removePayment = (state: any, payload: TRemovePaymentParameter) => {
  const new_state = _.cloneDeep(state);
  delete new_state.PAYMENTS[payload.hash];
  return _.assign({}, state, new_state);
};

export type TUpdateDiscountPayload = {
  discount_type: string;
  amount: number;
  price: number;
  total_price: number;
};
export const updateDiscount = (state: any, payload: TUpdateDiscountPayload) => {
  const new_state = _.cloneDeep(state);
  if (payload.discount_type === 'VIP_POINTS_DISCOUNT') {
    new_state.POINTS_SPENT = payload.amount;
  }
  new_state.SURCHARGE_DISCOUNT_COST[payload.discount_type] = {
    AMOUNT: payload.amount,
    MODE: 'DISCOUNT',
    PRICE: payload.price,
    TOTAL_PRICE: payload.total_price,
    TYPE: payload.discount_type,
  };
  return _.assign({}, state, new_state);
};

export type TRemoveDiscountPayload = {
  discount_type: string;
};
export const removeDiscount = (state: any, payload: TRemoveDiscountPayload) => {
  const new_state = _.cloneDeep(state);
  delete new_state.SURCHARGE_DISCOUNT_COST[payload.discount_type];
  if (payload.discount_type === 'VIP_POINTS_DISCOUNT') {
    new_state.POINTS_SPENT = 0;
  }
  return _.assign({}, state, new_state);
};
