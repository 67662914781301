import { TDictionary, TTranslation } from '@shared/types';
import _ from 'lodash';

export const trReplaceVariables = (
  text: string,
  variables: TDictionary<string>,
): string => {
  const regex = new RegExp(
    '\\${(' + Object.keys(variables).join('|') + ')}',
    'g',
  );
  // Replace the string by the value in object
  return text.replace(regex, (m, $1) => variables[$1] || m);
};
export const realTr = (
  tr: string | TTranslation | undefined | null,
  locale: string,
  variables?: TDictionary<string>,
) => {
  if (_.isNil(tr)) return null;
  if (Object.keys(tr).length === 0) return null;
  if (_.isString(tr)) return tr;
  const d = _.mapKeys(tr, (v: string, k: string) => {
    return k.toLowerCase();
  });
  const translatedText =
    _.get(d, 'all') ||
    _.get(d, locale.toLowerCase()) ||
    _.get(d, 'en') ||
    _.get(Object.values(d), '[0]');
  if (_.isNil(translatedText)) return null;

  return variables
    ? trReplaceVariables(translatedText, variables)
    : translatedText;
};
