// eslint-disable-next-line no-unused-vars
import React, { Fragment, useContext, useEffect } from 'react';
import { registerShopFields } from '../form/fields/shop';
// noinspection ES6CheckImport
import { MuiCookie, MuiDefaultTheme, MuiGlobal, useWebapi } from '@lsoft/mui';

import { appConfig } from '../../app.config';
import _ from 'lodash';
import { webapi } from '../../webapi/api';
import { useSnackbar } from 'notistack';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Cookies from 'universal-cookie';
import absoluteUrl from 'next-absolute-url/index';
import { Session } from '../container/shop/Session';
import { GTMProvider } from '../container/shop/GTMProvider';
import { useRouter } from 'next/router';
import Head from 'next/head';

import { ShopAppContainer } from './ShopAppContainer';
import { LoadingOverlay } from '../components/LoadingOverlay';
import { ShopSnackbarProvider } from '../components/ShopSnackbarProvider';
import { ErrorView } from '../components/Error/ErrorView';
// import { initSentry } from '../utils/sentry';
// import * as Sentry from '@sentry/react';
import { getTranslation } from '@lsoft/translate';
import { trDefaultOgDescription } from './ShopApp.tr';
import { GTMProductList } from '../container/shop/GTMProductList';
import { GTMCheckoutProvider } from '../container/shop/GTMCheckoutProvider';
import {
	generateAndSetClientIdIfMissing,
	setWebapiCookieAcceptDefaultIfMissing,
} from '@lsoft/shared/functions/cookie';
import { ApplicationModulesContainer } from '@lsoft/shared/container/application-modules/ApplicationModules';
import { ShopMode } from '../components/ShopMode/ShopMode';
import { AmProvider } from '@lsoft/shared/components/am/AmProvider';
import { AmContext } from '@lsoft/shared/components/am/AmContext';
import jwt from 'jsonwebtoken';
import { Breakpoint } from '@lsoft/shared/components/ui/Breakpoint';

const development_mode = process.env.NODE_ENV !== 'production';

// Store AppConfig globally
MuiGlobal.setMultiple(
	_.assign(
		{},
		{
			appDefaultLoginMode: 'username',
			webapi,
			development_mode,
			production_mode: !development_mode,
		},
		appConfig,
	),
);

// Registration of shop related MuiFormFields
registerShopFields();

// Init sentry
// initSentry();

export const ssrRender = typeof window === 'undefined';

const appPropsFromContext = (ctx) => {
	const am_auth_token = _.get(ctx.query, '_am_auth_token', null);
	const { session, cookies, protocol, host, origin, pathname, asPath } = ctx;
	return {
		am_auth_token,
		session,
		cookies,
		protocol,
		host,
		origin,
		pathname,
		asPath,
	};
};

const getFullPageTitle = (pageInfo) => {
	const { appTitle } = appConfig;
	if (_.isNil(pageInfo)) return appTitle;
	const pageTitle = _.get(pageInfo, 'title', null);
	if (_.isNil(pageTitle)) return appTitle;
	return `${appTitle} | ${pageTitle}`;
};

const defaultOgImageUri = '/images/og_image_default_landscape.png';

const getOgImageUri = (pageInfo) => {
	if (_.isNil(pageInfo)) return defaultOgImageUri;
	const ogImageUri = _.get(pageInfo, 'ogImageUri', null);
	return !_.isNil(ogImageUri) ? ogImageUri : defaultOgImageUri;
};

const getOgDescriptionTr = (pageInfo) => {
	if (_.isNil(pageInfo)) return trDefaultOgDescription;
	const ogDescription = _.get(pageInfo, 'ogDescription', {});
	return !_.isEmpty(ogDescription) ? ogDescription : trDefaultOgDescription;
};

const getAlternateHrefs = (origin, pathname, locales) => {
	return _.concat(
		locales.map((lc) => {
			const href = `${origin}/${lc}${pathname}`;
			return <link key={lc} rel={'alternate'} hrefLang={lc} href={href} />;
		}),
		<link
			key={'x-default'}
			rel={'alternate'}
			hrefLang={'x-default'}
			href={`${origin}${pathname}`}
		/>,
	);
};

const removeServerJSS = () => {
	// Remove the server-side injected CSS.
	const jssStyles = document.querySelector('#jss-server-side');
	if (jssStyles) {
		jssStyles.parentElement.removeChild(jssStyles);
	}
};

const AmWebapiConnector = ({ children }) => {
	const { am_auth_token, loggedIn } = useContext(AmContext);
	const webapi = useWebapi();
	webapi.access_token = loggedIn ? am_auth_token : null;
	return children;
};

const SnackBarGlobalSetter = ({ children }) => {
	const snackbar = useSnackbar();
	MuiGlobal.set('snackbar', snackbar);
	return children;
};

// const Maintenance = ({ Component, pageProps, err, ...ctx }) => {
// 	// const maintenance_mode = MuiGlobal.get('MAINTENANCE_MODE', null);
// 	const maintenance_mode = true;
// };
/*

	<Maintenance component>
		<MaintenanceOn>
			<MaintenanceApp {...props}>
		</MaintenanceOn>
		<MaintenanceOff>
			<CustomApp {...props}>
		</MaintenanceOff>
	</Maintenance>


 */

// eslint-disable-next-line react/prop-types
const CustomApp = ({ Component, pageProps, err, ...ctx }) => {
	useEffect(removeServerJSS, []);

	const { locales, locale, push } = useRouter();
	let appProps = _.merge(
		{},
		appPropsFromContext(ctx),
		_.get(pageProps, 'appProps', null),
	);
	//session needs to be overwritten because of CART.POSITIONS
	const overwriteSession = _.get(pageProps, 'appProps.session', null);
	if (!_.isNil(overwriteSession)) {
		appProps = _.assign({}, appProps, {
			session: overwriteSession,
		});
	}
	const shop_mode = MuiGlobal.get('SHOP_MODE', 'default');
	const page_info = _.get(pageProps, 'pageInfo', null);

	const { theme, appName } = appConfig;

	const { asPath, origin, session } = appProps;
	const title = getFullPageTitle(page_info);
	const og_image_uri = getOgImageUri(page_info);
	const og_description = getTranslation(getOgDescriptionTr(page_info), locale);
	const alternate_hrefs = getAlternateHrefs(origin, asPath, locales);
	return (
		// <Sentry.ErrorBoundary fallback={'Et hat boom gemacht'}>
		<ShopMode shop_mode={shop_mode}>
			<MuiCookie.Container cookies={appProps.cookies}>
				<GTMProvider>
					<GTMProductList list={'unspecified'}>
						<Head>
							<title>{title}</title>
							<link rel={'icon'} type={'image/png'} href={'/favicon.png'} />
							<meta
								name="viewport"
								content="width=device-width, initial-scale=1.0, maximum-scale=1.0,user-scalable=0"
							/>
							<meta property="og:title" content={title} />
							{!_.isNil(og_description) && (
								<meta property="og:description" content={og_description} />
							)}
							<meta property="og:image" content={`${og_image_uri}`} />
							<meta name="twitter:card" content="summary_large_image" />
							<meta name="twitter:image" content={`${og_image_uri}`} />
							{alternate_hrefs}
						</Head>
						<ThemeProvider theme={!_.isNil(theme) ? theme : MuiDefaultTheme}>
							<CssBaseline />
							{process.env.NODE_ENV === 'development' && <Breakpoint />}
							<LoadingOverlay>
								<ShopSnackbarProvider>
									<SnackBarGlobalSetter>
										<MuiCookie.Context.Consumer>
											{(cookieCtx) => (
												<AmProvider
													am_auth_token={cookieCtx._am_auth_token}
													deleteToken={cookieCtx.deleteCookie}
												>
													<AmWebapiConnector>
														<ApplicationModulesContainer
															app_name={appName}
															modules={[
																'checkout_customer_card_spent_points',
																'checkout_voucher_code',
															]}
															menu_entries={[]}
														>
															<Session.Container
																session={session}
																appName={appName}
															>
																<GTMCheckoutProvider>
																	<Session.Context.Consumer>
																		{(sessionValue) => (
																			<ShopAppContainer
																				{...appProps}
																				loginFunc={sessionValue.login}
																				logoutFunc={sessionValue.logout}
																			>
																				{!_.isNil(pageProps) &&
																				pageProps.unexpectedError ? (
																					<ErrorView {...pageProps} err={err} />
																				) : (
																					<Fragment>
																						<Component
																							{...pageProps}
																							err={err}
																						/>
																					</Fragment>
																				)}
																			</ShopAppContainer>
																		)}
																	</Session.Context.Consumer>
																</GTMCheckoutProvider>
															</Session.Container>
														</ApplicationModulesContainer>
													</AmWebapiConnector>
												</AmProvider>
											)}
										</MuiCookie.Context.Consumer>
									</SnackBarGlobalSetter>
								</ShopSnackbarProvider>
							</LoadingOverlay>
						</ThemeProvider>
					</GTMProductList>
				</GTMProvider>
			</MuiCookie.Container>
		</ShopMode>
		// </Sentry.ErrorBoundary>
	);
};

CustomApp.getInitialProps = async ({ ctx }) => {
	// const { getState, dispatch } = ctx.store;
	const { req, res } = ctx;

	// Cookies
	const header = req && req.headers && req.headers.cookie;
	let { cookies } = new Cookies(header);
	cookies = await setWebapiCookieAcceptDefaultIfMissing(res, req, cookies);
	// insert client id if missing
	cookies = await generateAndSetClientIdIfMissing(res, req, cookies);

	// Origin / Path
	const { host, protocol, origin } = absoluteUrl(ctx.req);
	const { pathname, asPath } = ctx;

	// Session
	const { _webapi_ca, _webapi_client_id } = cookies;

	const session = await Session.load(
		!_.isNil(_webapi_ca),
		_webapi_client_id,
		appConfig.appName,
	);
	return {
		cookies,
		host,
		protocol,
		origin,
		pathname,
		asPath,
		session,
	};
};

export default CustomApp;
