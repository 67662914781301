import { TProps } from '@shared/types';
import { cn } from '@shadcn/lib/utils';

type TBreakpointProps = TProps<{}>;

export const Breakpoint = ({ children, className }: TBreakpointProps) => {
  return (
    <div
      className={cn(
        'fixed z-[20000] bg-white text-black border border-black bottom-0 right-0',
        'px-2',
        className,
      )}
    >
      <span className="ml-1 sm:hidden md:hidden lg:hidden xl:hidden">
        xs (&lt; 640px)
      </span>
      <span className="ml-1 hidden sm:inline md:hidden font-extrabold">sm</span>
      <span className="ml-1 hidden md:inline lg:hidden font-extrabold">md</span>
      <span className="ml-1 hidden lg:inline xl:hidden font-extrabold">lg</span>
      <span className="ml-1 hidden xl:inline font-extrabold">xl</span>
      <span className="ml-1 hidden 2xl:inline font-extrabold">2xl</span>
      <span className="ml-1 hidden 3xl:inline font-extrabold">3xl</span>
    </div>
  );
};
