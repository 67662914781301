export const FooterMenuService = {
	title: {
		ALL: 'Service',
	},
	items: {
		gift_finder: {
			link: '/gift_finder',
			title: {
				DE: 'Geschenkefinder',
				EN: 'Gift Finder',
				FR: 'Trouveur de cadeaux',
			},
		},
		customer_card: {
			link: '/customer_card',
			title: {
				DE: 'Kundenkarte',
				EN: 'Customer Card',
				FR: 'Carte client',
			},
		},
		giftcard: {
			link: '/giftcard',
			title: {
				DE: 'LToys Geschenkkarte',
				EN: 'LToys gift card',
				FR: 'LToys carte de cadeau',
			},
		},
		rare_sets: {
			link: '/old_rare_sets',
			title: {
				DE: 'Alte und seltene Sets',
				EN: 'Vintage and Rare Sets',
				FR: 'Sets anciens et rares',
			},
		},
	},
};
export const FooterMenuSupport = {
	title: {
		DE: 'Support',
		EN: 'Support',
		FR: 'Assistance',
	},
	items: {
		contact: {
			link: '/contact',
			title: { DE: 'Kontakt', EN: 'Contact', FR: 'Contact' },
		},
		shipping: {
			link: '/shipping',
			title: { DE: 'Versand', EN: 'Shipping', FR: 'Expédition' },
		},
		payment_methods: {
			link: '/payment',
			title: { DE: 'Zahlung', EN: 'Payment', FR: 'Paiement' },
		},
		// faq: {
		// 	link: '/faq',
		// 	title: {
		// 		DE: 'Fragen & Antworten',
		// 		EN: 'FAQ',
		// 		FR: 'Questions & Réponses',
		// 	},
		// },
	},
};
export const FooterMenuAboutUs = {
	title: {
		DE: 'Über Uns',
		EN: 'About Us',
		FR: 'À propos de nous',
	},
	items: {
		team: {
			link: '/our_story',
			title: {
				DE: 'Unsere Geschichte',
				EN: 'Our Story',
				FR: 'Notre histoire',
			},
		},
		gtc: {
			link: '/gtc',
			title: {
				DE: 'Allgemeine Geschäftsbedingungen',
				EN: 'General Terms and Conditions',
				FR: 'Conditions générales',
			},
		},
		terms_of_use: {
			link: '/terms_of_use',
			title: {
				DE: 'Nutzungsbedingungen',
				EN: 'Terms of Use',
				// prettier-ignore
				FR: 'Conditions d\'utilisation',
			},
		},
		legal_notice: {
			link: '/legal_notice',
			title: { DE: 'Impressum', EN: 'Legal Notice', FR: 'Mentions légales' },
		},
		privacy_policy: {
			link: '/privacy_policy',
			title: {
				DE: 'Datenschutzerklärung',
				EN: 'Privacy Policy',
				FR: 'Politique de confidentialité',
			},
		},
		cookie_policy: {
			link: '/cookie_policy',
			title: {
				DE: 'Cookie-Richtlinien',
				EN: 'Cookie Policy',
				FR: 'Politique des cookies',
			},
		},
	},
};

export const FooterMenuText = {
	items: {
		gtc: {
			link: '/gtc',
			title: {
				DE: 'AGB',
				EN: 'GTC',
				FR: 'CGV',
			},
		},
		legal_notice: {
			link: '/legal_notice',
			title: {
				DE: 'Impressum',
				EN: 'Legal Notice',
				FR: 'Mentions légales',
			},
		},
		terms_of_use: {
			link: '/terms_of_use',
			title: {
				DE: 'Nutzungsbedingungen',
				EN: 'Terms of Use',
				// prettier-ignore
				FR: 'Conditions d\'utilisation',
			},
		},
		privacy_policy: {
			link: '/privacy_policy',
			title: {
				DE: 'Datenschutzerklärung',
				EN: 'Privacy Policy',
				FR: 'Politique de confidentialité',
			},
		},
		cookie_policy: {
			link: '/cookie_policy',
			title: {
				DE: 'Cookie-Richtlinien',
				EN: 'Cookie Policy',
				FR: 'Politique des cookies',
			},
		},
	},
};
