import { makePick, pickDefault } from './input_components';
import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { funcWrap } from '../../../core/funcWrap';
import { Tr } from '@lsoft/translate';
import { LsPhoneInput } from '@lsoft/shared/components/ui/LsPhoneInput';

const pick = makePick(
	['fullWidth', 'value', 'displayEmpty', 'options', 'variant', 'error'],
	pickDefault,
);
const defaultPlaceholder = {
	EN: 'Please select',
	DE: 'Bitte wählen',
	FR: 'Choisir une option',
};

const Component = (newProps) => {
	// if (newProps.options.length === 0) return null;

	return (
		<LsPhoneInput
			{...newProps}
			defaultCountry={newProps.value2}
			decorationProps={{
				errors: newProps.errors,
			}}
		/>
	);
};

Component.propTypes = {
	newProps: PropTypes.object,
};

export const ic_phone = {
	// eslint-disable-next-line react/display-name
	Component,
	getNewProps: (newProps) => {
		newProps.onChange = funcWrap(newProps.onChange, (onChange) => async (v) => {
			onChange(v);
		});

		if (newProps.displayEmpty && !_.has(newProps, 'placeholder')) {
			newProps.placeholder = <Tr tr={defaultPlaceholder} />;
		}
		if (_.get(newProps, 'value', null) === null) {
			newProps.value = '';
		}
		return newProps;

		//	übergebene props abändern
	},
};
