import { makePick, pickDefault } from './input_components';
import React, { useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import deLocale from 'date-fns/locale/de';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { funcWrap } from '../../../core';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';

const pick = makePick(
	[
		'error',
		'value',
		'label',
		'size',
		'minDate',
		'maxDate',
		'showTodayButton',
		'fullWidth',
		'helperText',
	],
	pickDefault,
);

const Component = (newProps) => {
	const [on, set_on] = useState(!_.isNil(newProps.value));
	const RealInput = (
		<MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
			<KeyboardDatePicker
				{...pick(newProps)}
				allowKeyboardControl={false}
				format="dd.MM.yyyy"
				KeyboardButtonProps={{
					size: 'small',
				}}
				inputVariant={newProps.variant}
				InputLabelProps={{ ...newProps.InputLabelProps }}
				disabled={newProps.disabled || (!on && newProps.switchable)}
			/>
		</MuiPickersUtilsProvider>
	);
	if (!newProps.switchable) return RealInput;
	return (
		<FormControl fullWidth={newProps.fullWidth}>
			<Grid container>
				<Grid item>
					<Switch
						onChange={(e) => {
							if (!(e.target.value === 'false')) {
								newProps.onChange(null);
							}
							set_on(!on);
						}}
						checked={on}
						disabled={newProps.disabled}
						color={'primary'}
					/>
				</Grid>
				<Grid item>{RealInput}</Grid>
			</Grid>
		</FormControl>
	);
};

Component.propTypes = {
	newProps: PropTypes.object,
};

export const ic_date = {
	// eslint-disable-next-line react/display-name
	Component,
	getNewProps: (newProps) => {
		// newProps.label = trContext.getTranslationFromData(newProps.trLabel);
		newProps.onChange = funcWrap(newProps.onChange, (onChange) => async (e) => {
			if (_.isNull(e) || isNaN(Date.parse(e))) {
				onChange(null);
				return null;
			}
			e.setHours(0);
			e.setMinutes(0);

			const val = e.toISOString();
			onChange(val);
		});
		const val = _.get(newProps, 'value', null);
		if (!_.isNull(val)) {
			const newDate = new Date(val);
			newProps.value = newDate.toDateString();
		}
		if (_.isNil(val) && newProps.preventTodayIfValueIsNull) {
			newProps.value = null;
		}
		return newProps;
	},
};
