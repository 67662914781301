import { TProps } from '@shared/types';
import { cn } from '@shadcn/lib/utils';
import { cva, VariantProps } from 'class-variance-authority';

type TLsAlertProps = TProps<{
  color?: 'primary' | 'secondary' | 'error' | 'warning' | 'info';
  variant?: 'filled' | 'transparent' | 'outlined' | 'text';
}>;

const alertVariants = cva('', {
  variants: {
    color: {
      primary: '',
      secondary: '',
      info: '',
      error: '',
      warning: '',
    },
    variant: {
      filled: 'p-2 rounded-md border',
      transparent: 'p-2 rounded-md border',
      outlined: 'p-2 rounded-md border',
      text: '',
    },
  },
  compoundVariants: [
    {
      color: 'primary',
      variant: 'filled',
      class:
        'bg-alert-primary-filled border-alert-primary text-alert-primary-filled',
    },
    {
      color: 'primary',
      variant: 'transparent',
      class:
        'bg-alert-primary-transparent border-alert-primary text-alert-primary-transparent',
    },
    {
      color: 'primary',
      variant: 'outlined',
      class: 'border-alert-primary text-alert-primary',
    },
    {
      color: 'primary',
      variant: 'text',
      class: 'text-alert-primary',
    },
    {
      color: 'secondary',
      variant: 'filled',
      class:
        'bg-alert-secondary-filled border-alert-secondary text-alert-secondary-filled',
    },
    {
      color: 'secondary',
      variant: 'transparent',
      class:
        'bg-alert-secondary-transparent border-alert-secondary text-alert-secondary-transparent',
    },
    {
      color: 'secondary',
      variant: 'outlined',
      class: 'border-alert-secondary text-alert-secondary',
    },
    {
      color: 'secondary',
      variant: 'text',
      class: 'text-alert-secondary',
    },
    {
      color: 'info',
      variant: 'filled',
      class: 'bg-alert-info-filled border-alert-info text-alert-info-filled',
    },
    {
      color: 'info',
      variant: 'transparent',
      class:
        'bg-alert-info-transparent border-alert-info text-alert-info-transparent',
    },
    {
      color: 'info',
      variant: 'outlined',
      class: 'border-alert-info text-alert-info',
    },
    {
      color: 'info',
      variant: 'text',
      class: 'text-alert-info',
    },
    {
      color: 'error',
      variant: 'filled',
      class: 'bg-alert-error-filled border-alert-error text-alert-error-filled',
    },
    {
      color: 'error',
      variant: 'transparent',
      class:
        'bg-alert-error-transparent border-alert-error text-alert-error-transparent',
    },
    {
      color: 'error',
      variant: 'outlined',
      class: 'border-alert-error text-alert-error',
    },
    {
      color: 'error',
      variant: 'text',
      class: 'text-alert-error',
    },
    {
      color: 'warning',
      variant: 'filled',
      class:
        'bg-alert-warning-filled border-alert-warning text-alert-warning-filled',
    },
    {
      color: 'warning',
      variant: 'transparent',
      class:
        'bg-alert-warning-transparent border-alert-warning text-alert-warning-transparent',
    },
    {
      color: 'warning',
      variant: 'outlined',
      class: 'border-alert-warning text-alert-warning',
    },
    {
      color: 'warning',
      variant: 'text',
      class: 'text-alert-warning',
    },
  ],
  defaultVariants: {
    color: 'info',
    variant: 'transparent',
  },
});

export const LsAlert = ({
  children,
  className,
  color,
  variant,
}: TProps<TLsAlertProps>) => {
  return (
    <div className={cn(alertVariants({ color, variant }), className)}>
      {children}
    </div>
  );
};
