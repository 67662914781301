const inputComponents = Object.entries({
	...require('./combo'),
	...require('./radiogroup'),
	...require('./flags'),
	...require('./switch'),
	...require('./text'),
	...require('./password'),
	...require('./date'),
	...require('./checkbox'),
	...require('./select'),
	...require('./number'),
	...require('./simple_translation'),
	...require('./html_translation'),
	...require('./price'),
	...require('./filter_chips'),
	...require('./pin_code'),
	...require('./list'),
	...require('./list_select'),
	...require('./list_select_search'),
	...require('./phone'),
}).reduce((ret, [k, v]) => {
	ret[k.substr(3)] = v;
	return ret;
}, {});

module.exports = {
	...require('./input_components'),
	inputComponents,
};
