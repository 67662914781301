import { TDictionary, TProps, TTranslation } from '@shared/types';
import { cn } from '@shadcn/lib/utils';

import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { LsInputDecoration } from '@ui/LsInputDecoration';
import { trPhoneNumber } from '@shared/translations/fields';
import { E164Number, CountryCode } from 'libphonenumber-js/core';
// import { useLocale } from '@shared/nextjs/translate';

// @ts-ignore
import lang_de from 'react-phone-number-input/locale/de.json';
// @ts-ignore
import lang_en from 'react-phone-number-input/locale/en.json';
// @ts-ignore
import lang_fr from 'react-phone-number-input/locale/fr.json';
import { useLocale } from '@shared/legacy_compat/nextjs/translate/useLocale';
import { TLsFormInputDecorationProps } from '@ui/LsForm';

const lang: TDictionary<object> = {
  de: lang_de,
  en: lang_en,
  fr: lang_fr,
};

type TLsPhoneInputProps = TProps<{
  value?: string;
  onChange: (value: string) => void;
  label?: TTranslation | string;
  defaultCountry?: string;
  decorationProps?: TLsFormInputDecorationProps;
}>;

export const LsPhoneInput = ({
  className,
  value,
  onChange,
  label = trPhoneNumber,
  defaultCountry,
  decorationProps,
  ...props
}: TLsPhoneInputProps) => {
  const { selectedLocale } = useLocale();
  const labels = lang[selectedLocale ?? 'en'];
  return (
    <LsInputDecoration {...decorationProps} label={label}>
      {/* @ts-ignore */}
      <PhoneInput
        labels={labels}
        placeholder="Enter phone number"
        value={value}
        onChange={(n) => onChange(n as string)}
        className={cn(className)}
        defaultCountry={defaultCountry as CountryCode}
        international
        {...props}
      />
    </LsInputDecoration>
  );
};
