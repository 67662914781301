/* eslint-disable @next/next/no-img-element */
import { TProps } from '@shared/types';
import { cn } from '@shadcn/lib/utils';
import React from 'react';

export type TLsImageSrcSetFunction = (
  src: string,
  widths: number[],
) => { src: string; srcSet?: string };

export const cdnSrcSetFunction: TLsImageSrcSetFunction = (src, widths) => {
  const srcSet = widths
    .map((width) => `${src}?output_format=webp&width=${width} ${width}w`)
    .join(', ');
  return { src, srcSet };
};

type TLsImageProps = TProps<{
  srcSetFunction?: TLsImageSrcSetFunction;
  src: string;
  alt?: string;
  widths?: number[];
  sizes?: string;
  imgClasses?: string;
}>;

const lsImageDefaultWidths = [320, 640, 768, 1000, 1200, 1920];

export const LsImage = ({
  className,
  src,
  srcSetFunction,
  alt,
  widths = lsImageDefaultWidths,
  sizes = '100vw',
  imgClasses = '',
}: TLsImageProps) => {
  const realSrcProps = srcSetFunction ? srcSetFunction(src, widths) : { src };
  return (
    <div
      className={cn(
        'relative max-h-full max-w-full min-h-full min-w-full',
        className,
      )}
    >
      <div className={cn('')}>
        <img
          {...realSrcProps}
          style={{ color: 'transparent' }}
          className={cn(
            'absolute inset-0 object-contain w-full h-full',
            imgClasses,
          )}
          alt={alt ?? realSrcProps.src}
          sizes={sizes}
        />
      </div>
    </div>
  );
};
