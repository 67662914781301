import lsrestclient, {
  TLsRestClientCall,
} from '@shared/legacy_compat/lsrestclient/index';
import { TGiftcardBalance } from '@shared/types/giftcard';

type TApiGiftcardSaldoReadParameter = {
  giftcard_hash: string;
  online_code: string;
};
export const apiGiftcardBalanceRead = async ({
  giftcard_hash,
  online_code,
  config,
}: TLsRestClientCall<TApiGiftcardSaldoReadParameter>): Promise<
  TGiftcardBalance | undefined
> => {
  const client = lsrestclient.client();
  const params = { giftcard_hash, online_code };
  return await client({
    method: 'get',
    url: '/giftcard/balance',
    params: params,
    ...config,
  });
};
