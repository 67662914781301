import React, { useMemo } from 'react';
import { getTranslation } from '@lsoft/translate';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { LtoysVoucherImage } from 'packages/shared/shop/components/views/LtoysVoucherImage';
import { cn } from '@lsoft/shadcn/lib/utils';
import { trGiftCardTitle } from '@lsoft/shared/translations/giftcard';

export const HeaderLinkGiftCard = (props) => {
	const { locale } = useRouter();
	const title = useMemo(() => {
		return getTranslation(trGiftCardTitle, locale);
	}, [locale]);

	return (
		<Link href={'/giftcard'} title={title}>
			<div className={'flex items-center justify-center w-[56px]'}>
				<LtoysVoucherImage className={cn('w-full md:w-full')} shadow={false} />
			</div>
			{/*</IconButton>*/}
		</Link>
	);
};

HeaderLinkGiftCard.propTypes = {};
