import { LsImage } from '@ui/LsImage';
import React from 'react';
import { cn } from '@shadcn/lib/utils';
import { TProps } from '@shared/types';

type TLtoysVoucherImageProps = TProps<{
  shadow?: boolean;
}>;

export const LtoysVoucherImage = ({
  children,
  className,
  shadow = true,
}: TLtoysVoucherImageProps) => {
  return (
    <div className={cn('w-full aspect-[1016/638]', className)}>
      <LsImage
        src="/images/voucher/221129_RZ_Gutscheinkarte_1.png"
        alt="voucher"
        className={''}
        imgClasses={cn('rounded-lg object-cover', {
          'drop-shadow-lg': shadow,
        })}
      />
    </div>
  );
};
