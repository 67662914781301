import React, { useCallback, useEffect, useReducer } from 'react';
import PropTypes from 'prop-types'; // eslint-disable-line no-unused-vars
import { MuiFormContext } from './MuiFormContext';
import _ from 'lodash';
import { convertFromValue, convertToValue, optionalValue } from './Converter';
import { optional, optionalEmpty, validate } from './Validator';
import { MuiFormFieldTypes } from './MuiFormFieldTypes';

const isMuiFormValid = (fields) => {
	return !Object.values(fields)
		.map((values) => !values.valid)
		.some((value) => value);
};

const reducer = () => (state, action) => {
	const newState = _.assign({}, state);

	const inputValueChange = () => {
		const { fieldName, value, valid, errors } = action;
		// change value
		newState.fields[fieldName].data = value;
		newState.fields[fieldName].valid = valid;
		newState.fields[fieldName].errors = errors;
		newState.old_valid = newState.valid;
		newState.valid = isMuiFormValid(newState.fields);
		// console.log(
		// 	action.fieldName,
		// 	action.value,
		// 	action.valid,
		// 	action.errors,
		// 	newState.valid
		// );
		return newState;
	};

	switch (action.type) {
		case 'setValues':
			Object.entries(action.payload).map(([fieldName, value]) => {
				if (!_.isNil(_.get(newState, `fields.${fieldName}.data`, null))) {
					newState.fields[fieldName].data = value;
				}
			});
			return newState;
		case 'input_value_change':
			return inputValueChange();

		default:
			throw new Error();
	}
};

const resolveFields = ({ fields, fieldGlobals }) => {
	// console.log('resolve fields', fieldGlobals);
	const newFields = _.assign({}, fields);
	Object.entries(newFields).map(([name, f]) => {
		f = _.merge(
			{},
			{
				default: null,
				inputProps: {},
				validators: [],
				converters: [],
				selectorProps: ['value', 'errors', 'disabled'],
			},
			fieldGlobals,
			f,
		);
		// console.log('ff', global, f);
		const variant = _.get(f, 'variant', null);
		if (variant === null) return;
		let def = MuiFormFieldTypes.get(variant);

		if (def === null && variant === 'FILTER') {
			def = {
				inputComponent: null,
				inputProps: {},
				converters: [],
				validators: [],
			};
		}

		if (def === null)
			throw new Error(`MuiFormField variant '${variant}' not found.`);
		f.inputComponent = def.inputComponent;
		f.inputProps = _.assign({}, def.inputProps, f.inputProps);
		f.converters = _.concat(f.converters, _.get(def, 'converters', []));
		f.validators = _.concat(f.validators, _.get(def, 'validators', []));

		//Handle Optional, OptionalEmpty, OptionalValue
		if (_.get(f, ['optional']) || _.has(f, 'optionalEmpty')) {
			f.validators = _.concat([optional()], f.validators);
		}
		if (_.has(f, 'optionalEmpty')) {
			f.validators = _.concat([optionalEmpty(f.optionalEmpty)], f.validators);
			f.converters = _.concat(
				[
					optionalValue({
						optionalEmpty: f.optionalEmpty,
						optionalValue: f.optionalValue,
					}),
				],
				f.converters,
			);
		}
		f.selectorProps = _.concat(
			f.selectorProps,
			_.get(def, 'selectorProps', []),
		);
		newFields[name] = f;
	});
	// console.log('newFields', newFields);
	return newFields;
};

const getInitialState = ({ values, fields: origFields, fieldGlobals }) => {
	const fields = resolveFields({ fields: origFields, fieldGlobals });
	const initialFieldValues = Object.entries(fields).reduce((ret, [f, d]) => {
		let defaultValue = _.get(d, 'default', null);
		if (_.isFunction(defaultValue)) {
			defaultValue = defaultValue();
		}
		const data = _.isNil(values)
			? defaultValue
			: _.get(values, f, defaultValue);
		const { valid, errors } = validate(data, _.get(d, 'validators', []));

		ret[f] = {
			data: data,
			spec: d,
			valid: valid,
			old_valid: valid,
			errors: errors,
			included: _.get(d, 'included', true),
			filter: _.get(d, 'filter', false),
			formDataValueMapping: _.get(d, 'formDataValueMapping', {}),
			propsSelector: (props) => d.selectorProps.map((p) => props[p]),
		};

		return ret;
	}, {});

	return {
		fields: initialFieldValues,
		valid: isMuiFormValid(initialFieldValues),
	};
};

// eslint-disable-next-line react/display-name
const MuiForm = (props) => {
	// console.log('render MuiForm');
	const { children, values, fields, fieldGlobals } = props;
	const [state, dispatch] = useReducer(
		reducer(),
		{ values, fields, fieldGlobals },
		getInitialState,
	);
	// return <ReactJson src={state} />;
	// return <div>blah</div>;
	const getFormData = useCallback(
		(state) => {
			// console.log('getFormData');
			return (
				Object.entries(state.fields)
					// eslint-disable-next-line no-unused-vars
					.filter(([k, v]) => v.included)
					.reduce((ret, [k, v]) => {
						ret[k] = v.data;
						return ret;
					}, {})
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[props],
	);

	const getFilterFormData = useCallback(
		(state) => {
			let allData = getFormData(state);
			const filterData = Object.entries(state.fields)
				// eslint-disable-next-line no-unused-vars
				.filter(([k, v]) => {
					if (v.filter) {
						delete allData[k];
					}
					return v.filter;
				})
				.reduce(
					(ret, [k, v]) => {
						//usage of value2 as parameter for filtermapping callback parameter
						const fieldValue2Name = _.get(v, ['spec', 'value2'], 'null');
						const value2 = _.get(allData, fieldValue2Name, null);
						const formDataValueMapping = _.isFunction(v.formDataValueMapping)
							? v.formDataValueMapping(value2)
							: v.formDataValueMapping;

						const mappedData = _.get(formDataValueMapping, v.data, v.data);
						if (_.isNil(mappedData)) {
							return ret;
						}
						// const key = _.get(Object.keys(mappedData), '[0]', k);
						// const val = _.get(formDataValueMapping, [v.data, key], v.data);
						ret['FILTER'][k] = mappedData;
						// ret['FILTER'][key] = val;
						return ret;
					},
					{ FILTER: {} },
				);
			return _.assign({}, allData, filterData);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[props],
	);

	const getValue = useCallback(
		(fieldName) => {
			const field = _.get(state, ['fields', fieldName], null);
			if (field === null)
				throw new Error(`Field with name'${fieldName}' not found.`);
			return convertFromValue(
				field.data,
				_.get(field, ['spec', 'converters'], []),
			);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[props],
	);

	const setValue = useCallback(
		async (fieldName, val) => {
			// arm update

			// console.log('setValue', fieldName);
			const field = state.fields[fieldName];
			const { converters, validators } = field.spec;
			// console.log('setValue2', fieldName, field);

			const newValue = convertToValue(val, converters);
			const { valid, errors } = validate(newValue, validators);
			await dispatch({
				type: 'input_value_change',
				fieldName: fieldName,
				value: newValue,
				valid: valid,
				errors: errors,
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[props],
	);

	const setValues = useCallback(
		async (data) => {
			await dispatch({
				type: 'setValues',
				payload: data,
			});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[props],
	);

	const formData = getFormData(state);
	const filterFormData = getFilterFormData(state);

	useEffect(() => {
		// console.log('useeffect', state.valid, propagationArmed.current);
		props.onChange(formData, filterFormData);
		if (state.old_valid !== state.valid) props.onValidChange(state.valid);
		if (state.valid) {
			// console.log('call onChangeIfValid');
			props.onChangeIfValid(formData, filterFormData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state]);

	const contextValue = {
		valid: state.valid,
		fields: state.fields,
		getValue,
		setValue,
		setValues,
		formData,
		filterFormData,
	};
	console.log('contextValue', contextValue);
	return (
		<MuiFormContext.Provider value={contextValue}>
			{children}
		</MuiFormContext.Provider>
	);
};

MuiForm.propTypes = {
	fields: PropTypes.object.isRequired,
	fieldGlobals: PropTypes.object,
	values: PropTypes.object,
	onChangeIfValid: PropTypes.func,
	onChange: PropTypes.func,
	onValidChange: PropTypes.func,
};
MuiForm.defaultProps = {
	values: null,
	onChangeIfValid: () => {},
	onChange: () => {},
	onValidChange: () => {},
};
export { MuiForm };
