import React, { useCallback, useContext, useMemo } from 'react';
import { Session } from './Session';
import _ from 'lodash';
import update from 'immutability-helper';
import { makeSubContainer } from '../../tools/containerTools';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { getTranslation } from '@lsoft/translate';
import {
	trArticleSuccessFullAddedToCart,
	trYourShoppingCartHasBeenUpdated,
} from './Cart.tr';
import { useShopSnackbar } from '../../hooks/useShopSnackbar';
import { sku_is_voucher } from '@lsoft/shared/functions/product';
import {
	updatePayment,
	removePayment,
	updateDiscount,
	removeDiscount,
} from './CardHelper';
import { apiGiftcardBalanceRead } from '@lsoft/shared/api/giftcard';
import { generateId } from '@lsoft/jstools/generateId';

import { CartContext as Context } from '@lsoft/shared/shop/old_contexts/CartContext';
import {
	trErrorGiftcardAlreadyAdded,
	trErrorGiftcardNoBalance,
	trErrorGiftcardNumberOrOnlineCodeWrong,
} from '@lsoft/shared/translations/giftcard';
import { trErrorUnknown } from '@lsoft/shared/translations/app';
import { LoadingOverlayContext } from '../../components/LoadingOverlay';

const debug_log = false;
const single_point_value = 0.05; // Base data

const update_positions = (state, positions) => {
	const goodsValue =
		Math.round(
			_.sum(
				Object.values(positions).map((pos) => {
					if (_.isNil(pos)) return 0;
					return Math.round(pos.AMOUNT * pos.PRICE * 100) / 100;
				}),
			) * 100,
		) / 100;

	const ret = update(state, {
		GOODS_VALUE: {
			$set: goodsValue,
		},
		POSITIONS: { $set: positions },
	});

	if (debug_log) console.log('update_positions new_state', ret);

	return ret;
};

const adjust_position = (state, position) => {
	const skuStr = position.SKU.toString();
	const positions =
		position.AMOUNT === 0
			? _.omit(state.POSITIONS, [skuStr])
			: _.assign({}, state.POSITIONS, { [skuStr]: position });
	return update_total_weight(update_positions(state, positions));
};

export const convert_raw_product_data_to_session_product = (
	SKU,
	AMOUNT,
	PRODUCT_DATA,
) => {
	return {
		SKU: SKU,
		AMOUNT: AMOUNT,
		NAME_TEXT: PRODUCT_DATA.name_text,
		PRICE: PRODUCT_DATA.real_brutto_sp,
		ACTIVE: PRODUCT_DATA.status,
		LOGISTIC_WEIGHT: PRODUCT_DATA.logistic_weight,
		TAX_TYPE: PRODUCT_DATA.tax_type,
		AVAILABLE: PRODUCT_DATA.delivery_time_working_days === 1,
	};
};

const update_total_weight = (state) => {
	const TOTAL_LOGISTIC_WEIGHT =
		Math.round(
			_.sum(
				Object.values(state.POSITIONS).map((pos) => {
					if (_.isNil(pos)) return 0;
					return Math.round(pos.AMOUNT * pos.LOGISTIC_WEIGHT * 1000) / 1000;
				}),
			) * 100,
		) / 100;
	return _.assign({}, state, { TOTAL_LOGISTIC_WEIGHT });
};

const cart_reducer = (state, action) => {
	switch (action.type) {
		case 'adjustPosition':
			return adjust_position(state, action.payload);
		case 'addPosition':
			return adjust_position(state, {
				...action.payload,
				AMOUNT:
					_.get(state.POSITIONS, [action.payload.SKU.toString(), 'AMOUNT'], 0) +
					action.payload.AMOUNT,
			});
		case 'setCartTaxRates':
			return _.assign({}, state, { TAX_TYPES: action.payload });
		case 'updateDiscount':
			return updateDiscount(state, action.payload);
		case 'removeDiscount':
			return removeDiscount(state, action.payload);

		case 'setShippingMethod':
			return _.assign({}, state, {
				SHIPPING_METHOD: action.payload.SHIPPING_METHOD,
			});
		case 'updatePayment':
			return updatePayment(state, action.payload);
		case 'removePayment':
			return removePayment(state, action.payload);

		default:
			throw new Error(`no reducer for action '${action.type}'`);
	}
};

const CartContainer = ({ state, dispatch, makeValues, children }) => {
	const { setLoading } = useContext(LoadingOverlayContext);

	const { setUseSaveSessionResult } = useContext(Session.Context);

	const { locale } = useRouter();
	const throwSnackbar = useShopSnackbar();

	const unavailable_pos_in_cart = useMemo(() => {
		const positions = _.get(state, 'POSITIONS', {});
		return !_.isNil(
			Object.values(positions).find((position) => !position.AVAILABLE),
		);
	}, [state]);

	const voucher_only = useMemo(() => {
		const positions = _.get(state, 'POSITIONS', {});
		if (positions.length === 0) {
			return false;
		}
		return _.every(
			Object.values(positions).map((p) => sku_is_voucher(p.SKU)),
			Boolean,
		);
	}, [state]);

	const voucher_exists = useMemo(() => {
		const positions = _.get(state, 'POSITIONS', {});
		if (positions.length === 0) {
			return false;
		}
		return _.some(
			Object.values(positions).map((p) => sku_is_voucher(p.SKU)),
			Boolean,
		);
	}, [state]);

	const updateDiscount = useCallback(
		async ({ discount_type, amount, price, total_price }) => {
			await setLoading(true, true);
			await setUseSaveSessionResult(true);
			await dispatch({
				type: 'updateDiscount',
				payload: { discount_type, amount, price, total_price },
			});
		},
		[setUseSaveSessionResult, dispatch, setLoading],
	);

	const removeDiscount = useCallback(
		async (discount_type) => {
			await setLoading(true, true);
			await setUseSaveSessionResult(true);
			await dispatch({
				type: 'removeDiscount',
				payload: { discount_type },
			});
		},
		[setUseSaveSessionResult, dispatch, setLoading],
	);

	const convert_points = useCallback(
		async (point_count) => {
			const discount_type = 'VIP_POINTS_DISCOUNT';
			if (point_count === 0) {
				await removeDiscount(discount_type);
			} else {
				const price = -Math.abs(single_point_value);
				const total_price = -Math.abs(single_point_value * point_count);
				await updateDiscount({
					discount_type,
					price,
					total_price,
					amount: point_count,
				});
			}
		},
		[setUseSaveSessionResult, dispatch, removeDiscount, updateDiscount],
	);

	const position_add = useCallback(
		(PRODUCT_DATA) => {
			// await setLoading(true, true);
			// await setUseSaveSessionResult(true);
			dispatch({
				type: 'addPosition',
				payload: { ...PRODUCT_DATA },
			});
			throwSnackbar({
				msg: getTranslation(trArticleSuccessFullAddedToCart, locale),
				variant: 'success',
			});
		},
		[locale],
	);

	const position_adjust = useCallback(
		(PRODUCT_DATA) => {
			// await setLoading(true, true);
			// await setUseSaveSessionResult(true);
			dispatch({
				type: 'adjustPosition',
				payload: { ...PRODUCT_DATA },
			});
			throwSnackbar({
				msg: getTranslation(trYourShoppingCartHasBeenUpdated, locale),
				variant: 'success',
			});
		},
		[locale],
	);

	const position_remove = useCallback(
		async (SKU) => {
			await setLoading(true, true);
			await setUseSaveSessionResult(true);
			await dispatch({
				type: 'adjustPosition',
				payload: { SKU, AMOUNT: 0 },
			});
			await throwSnackbar({
				msg: getTranslation(trYourShoppingCartHasBeenUpdated, locale),
				variant: 'success',
			});
		},
		[locale],
	);

	const removePayment = useCallback(
		async (hash) => {
			await setLoading(true, true);
			await setUseSaveSessionResult(true);
			await dispatch({
				type: 'removePayment',
				payload: { hash },
			});
		},
		[dispatch, setLoading],
	);

	const updatePayment = useCallback(
		async ({ payment_method, hash, giftcard_hash, amount }) => {
			await setLoading(true, true);
			await dispatch({
				type: 'updatePayment',
				payload: { payment_method, hash, giftcard_hash, amount },
			});
		},
		[dispatch, setLoading],
	);

	const updateGiftcard = useCallback(
		async ({ giftcard_hash, online_code }) => {
			await setLoading(true, true);
			await setUseSaveSessionResult(true);
			const voucherHashes = Object.values(state.PAYMENTS)
				.filter((p) => p.PAYMENT_METHOD === 'VOUCHER')
				.map((p) => p.VOUCHER_HASH);

			if (voucherHashes.includes(giftcard_hash)) {
				return {
					errorClass: 'ALREADY_ADDED',
					errorMessage: trErrorGiftcardAlreadyAdded,
				};
			}

			try {
				const res = await apiGiftcardBalanceRead({
					giftcard_hash,
					online_code,
				});

				if (res.BALANCE <= 0) {
					return {
						errorClass: 'NO_BALANCE',
						errorMessage: trErrorGiftcardNoBalance,
					};
				}

				const amount =
					state.TOTAL_PRICE > res.BALANCE ? res.BALANCE : state.TOTAL_PRICE;

				const hash1 = generateId('PAYMENT', 15);

				await updatePayment({
					payment_method: 'VOUCHER',
					hash: hash1,
					giftcard_hash,
					amount,
				});

				return null;
			} catch (err) {
				const statusCode = _.get(err, 'statusCode', null);
				if (statusCode === 403) {
					return {
						statusCode,
						errorClass: 'FORBIDDEN',
						errorMessage: trErrorGiftcardNumberOrOnlineCodeWrong,
					};
				} else {
					return {
						statusCode,
						errorClass: 'UNKNOWN_ERROR',
						errorMessage: trErrorUnknown,
					};
				}
			}
		},
		[updatePayment, state, setUseSaveSessionResult, setLoading],
	);

	const set_shipping_method = useCallback(
		async (SHIPPING_METHOD) => {
			await setLoading(true, true);
			await setUseSaveSessionResult(true);
			await dispatch({
				type: 'setShippingMethod',
				payload: { SHIPPING_METHOD },
			});
		},
		[setUseSaveSessionResult, dispatch, setLoading],
	);

	const set_cart_tax_rates = useCallback((taxRates) => {
		dispatch({
			type: 'setCartTaxRates',
			payload: taxRates,
		});
	}, []);

	const total_item_count = useMemo(() => {
		if (_.isNil(state)) return 0;
		else return _.sum(Object.values(state.POSITIONS).map((pos) => pos.AMOUNT));
	}, [state]);

	const value = makeValues({
		convert_points,
		position_add,
		position_adjust,
		position_remove,
		setShippingMethod: set_shipping_method,
		setCartTaxRates: set_cart_tax_rates,
		updateDiscount,
		removeDiscount,
		updateGiftcard,
		removePayment,
		total_item_count,
		unavailable_pos_in_cart,
		voucher_only,
		voucher_exists,
		...state,
	});

	if (debug_log) console.log('Cart.Context state', state);

	return <Context.Provider value={value}>{children}</Context.Provider>;
};
CartContainer.propTypes = {
	state: PropTypes.object,
	makeValues: PropTypes.func.isRequired,
};

const Container = makeSubContainer(
	Session.Context,
	'CART',
	cart_reducer,
)(CartContainer);
Container.displayName = 'Cart.Container';

export const Cart = {
	Container,
	Context,
};
